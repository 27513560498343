<template>
  <div>
    <v-simple-table dense>
      <template v-slot:default>
        <thead>
        <tr>
          <th class="text-uppercase">
            ID
          </th>
          <th class="text-center text-uppercase">
            Name
          </th>
          <th class="text-center text-uppercase">
            Net Collection
          </th>
          <th class="text-center text-uppercase">
            Payment For
          </th>
          <th class="text-center text-uppercase">
            Actions
          </th>
        </tr>
        </thead>
        <tbody>
        <tr
          v-for="item in payments_data"
          :key="item.id"
        >
          <td>{{ item.id_no }}</td>
          <td class="text-center">
            {{ item.name }}
          </td>
          <td class="text-center">
            {{ item.amount }}
          </td>
          <td class="text-center">
            {{ item.payment_for }}
          </td>
          <td class="text-center">
            <div>
              <v-icon
                class="mr-2"
                color="error"
                @click="delete_payment(payments_data.map(function(x) {return x.id; }).indexOf(item.id))"
              >
                {{icons.mdiDelete}}
              </v-icon>
            </div>
          </td>
        </tr>
        </tbody>
      </template>
    </v-simple-table>

    <snack-bar-dialog
      :snackbar_flag="this.snackbar"
      :color="this.snackbar_color"
      :snackbar_text="this.snackbar_text"
    />
  </div>
</template>

<script>
  import {mdiDelete} from "@mdi/js";
  import {mapActions, mapGetters} from "vuex";
  import moment from "moment";
  import snackBarDialog from "@/components/dialogs/notifications_dialog/Snackbar";

  export default {
    components: {
      snackBarDialog,
    },
    setup() {
      return {
        icons: {
          mdiDelete,
        },
      }
    },
    props: {
      payments_data: Array,
      collector_id: Number,
    },
    computed: {
      ...mapGetters('authentication', ['branch_id']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text']),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('members_payment', ['delete_members_payment']),
      ...mapActions('induction_history_payments', ['delete_induction_payment']),
      ...mapActions('induction_tickets_payments', ['delete_induction_ticket_payment']),
      delete_payment(index) {
        var data = this.payments_data[index]
        var splited_id = data.id.split('-')
        if (splited_id[1] === 'members_payment') {
          this.delete_members_payment({
            members_payment_id: splited_id[0],
            member_id: data.member_id,
            restored_amount: data.amount,
            deposit_slip_id: data.deposit_slip_id,

          })
            .then(() => {
              this.change_snackbar({
                show: true,
                color: 'error',
                text: ' DELETED SUCCESSFULLY !',
              })
              this.$emit('collector', this.collector_id)
              this.saving = false
            })
            .catch(error => {
              this.alert = true
              this.alert_message = error
              this.saving = false
            })
        } else if (splited_id[1] === 'inductions') {
          this.delete_induction_payment({
            members_payment_id: splited_id[0],
            member_id: data.member_id,
            restored_amount: data.amount,
            deposit_slip_id: data.deposit_slip_id,

          })
            .then(() => {
              this.change_snackbar({
                show: true,
                color: 'error',
                text: ' DELETED SUCCESSFULLY !',
              })
              this.$emit('collector', this.collector_id)
              this.saving = false
            })
            .catch(error => {
              this.alert = true
              this.alert_message = error
              this.saving = false
            })
        }else if (splited_id[1] === 'inductions_tickets') {
          this.delete_induction_ticket_payment({
            members_payment_id: splited_id[0],
            restored_amount: data.amount,
            deposit_slip_id: data.deposit_slip_id,

          })
            .then(() => {
              this.change_snackbar({
                show: true,
                color: 'error',
                text: ' DELETED SUCCESSFULLY !',
              })
              this.$emit('collector', this.collector_id)
              this.saving = false
            })
            .catch(error => {
              this.alert = true
              this.alert_message = error
              this.saving = false
            })
        }
      }
    },
  }
</script>
