<template>
  <div>
    <v-row dense>
      <v-col
        md="3"
        cols="12"
      >
        <deposit-slip-info
          v-on:data="on_selected_collector"
          :collector="collector"
        ></deposit-slip-info>
      </v-col>
      <v-col
        md="9"
        cols="12"
      >
        <v-card
          flat
          dense
          v-if="this.collector_id!=0"
        >
          <v-toolbar dense dark color="primary">
            <v-toolbar-title><h5 class="font-weight-light">PAYMENTS SECTION</h5>
            </v-toolbar-title>
            &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;
            <strong>
              <h3 class="font-weight-light"> Overall Balance: {{balance}}</h3>
            </strong>
          </v-toolbar>
          <v-card-text>
            <v-col
              md="12"
              cols="12"
            >
            </v-col>
            <v-row dense>
              <v-col
                md="12"
                cols="12"
              >
                <v-select
                  outlined
                  v-model="payment_option"
                  class="mx-2"
                  :items="['Members Payment','Induction Payment','Tickets Payment']"
                  label="Option"
                  required
                ></v-select>
              </v-col>
              <v-col
                md="12"
                cols="12"
              >
                <v-combobox
                  class="mx-2"
                  :append-icon="icons.mdiAccountSearchOutline"
                  v-model="search"
                  @keyup="searching($event.target.value)"
                  @change="get_search_items_info"
                  :items="search_items"
                  item-value="id"
                  item-text="name"
                  placeholder="Search by (Lastname/Firstname/CMF #/Account #)"
                  ref="memberCombobox"
                  dense
                ></v-combobox>
              </v-col>
              <v-col
                md="4"
                cols="12"
              >
                <v-text-field
                  v-model="id_no"
                  label="ID #"
                  dense
                  outlined
                  readonly
                ></v-text-field>
              </v-col>
              <v-col
                md="8"
                cols="12"
              >
                <v-text-field
                  v-model="name"
                  label="Name"
                  dense
                  outlined
                  readonly
                ></v-text-field>
              </v-col>
              <v-col
                md="12"
                cols="12"
              >
                <payment-sections
                  v-on:data="on_save"
                  v-on:collector="on_save"
                  :collector_id="collector_id"
                  :input_amount="input_amount"
                  :payment_option="payment_option"

                  :selected_search_items="selected_search_items"

                  :deposit_slip_id="deposit_slip_id"
                  :balance="balance"
                  :or_series_available="or_series_available"
                  :or_series_available_id="or_series_available_id"
                ></payment-sections>
              </v-col>
              <v-col
                md="12"
                cols="12"
              >
                <h2>Payments Data <span class=" font-weight-light"
                                        v-if="payments_data.length>0">
                  |
                                                Print ARS
                                                <v-icon
                                                  class="mr-2"
                                                  color="warning"
                                                  @click="print_selected_deposit_slip_data()"
                                                >
                                                {{icons.mdiPrinter}}
                                                </v-icon>
                                            </span></h2>


                <payments-data
                  v-on:collector="on_save"
                  :collector_id="collector_id"
                  :payments_data="payments_data"></payments-data>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import {mdiAccountSearchOutline, mdiPrinter} from '@mdi/js'
  import {mapGetters, mapActions} from "vuex";
  import DepositSlipInfo from '@/views/payments/payment_for/PaymentsDepositSlipInfo'
  import PaymentSections from '@/views/payments/payment_for/PaymentSections'
  import PaymentsData from '@/views/payments/payment_for/PaymentsData'
  import moment from "moment";

  export default {
    components: {
      DepositSlipInfo,
      PaymentSections,
      PaymentsData,
    },
    setup() {
      return {
        icons: {
          mdiAccountSearchOutline,
          mdiPrinter,
        },
      }
    },
    data() {
      return {
        payment_option: '',
        search: '',
        search_member: '',
        search_items: [],
        collectors: {},
        selected_search_items: null,

        collector: 0,
        collector_id: 0,
        deposit_slip_id: 0,
        balance: 0,
        or_series_available: 'NA',
        or_series_available_id: 0,

        id_no: '',
        name: '',
        input_amount: false,

        payments_data: [],
      }
    },
    watch: {
      search_member: function () {
        if (this.timeout) clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
          if (this.search_member == '') {
            this.search_items = []
          } else {
            this.searching_search_items(this.search_member)
          }
        }, 500)
      }
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('authentication', ['branch_id']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text', 'company_logo']),
    },
    methods: {
      ...mapActions('members_information', ['search_members_paid', 'search_all_regular_members']),
      ...mapActions('induction_tickets', ['search_unpaid_tickets']),
      reset() {
        this.search = ''
        this.search_items = []
        this.search_member = ''
        this.selected_search_items = null
        this.id_no = ''
        this.name = ''
      },
      on_selected_collector(value) {
        this.search = ''
        this.search_items = []
        this.search_member = ''
        this.selected_search_items = null
        this.id_no = ''
        this.name = ''

        this.collectors = value
        this.collector_id = value.collector_id
        this.deposit_slip_id = value.deposit_slip_id
        this.balance = parseFloat(value.balance)

        this.payments_data = value.payments_data
        this.collector = -1
      },
      on_save(value) {
        this.collector = value
      },
      searching(value) {
        this.search_member = value
      },
      searching_search_items(value) {
        this.input_amount = false
        if (this.payment_option === 'Tickets Payment') {
          this.search_unpaid_tickets({
            search_word: value
          })
            .then(response => {
              this.search_items = response.data
            })
        } else {
          this.search_all_regular_members({
            branch_id: this.branch_id,
            search_word: value
          })
            .then(response => {
              this.search_items = response.data
            })
        }
      },
      get_search_items_info() {
        if (this.search_items.length > 0) {
          var index = this.search_items.map(function (x) {
            return x.id;
          }).indexOf(this.search.id)
          if (index != -1) {
            this.selected_search_items = this.search_items[index]
            this.id_no = this.selected_search_items.id_no
            this.name = this.selected_search_items.name
          } else {
            this.name = ''
            this.id_no = ''
          }
        } else {
          this.name = ''
          this.id_no = ''
        }
      },
      print_selected_deposit_slip_data() {
        var imgData = this.company_logo
        var payments_array = []

        var month = this.collectors.month_of
        var collector = this.collectors.name
        var dep_id = this.collectors.deposit_slip_id
        var dep_amount = this.collectors.amount
        var total_net = this.collectors.net
        var total_diff = this.collectors.balance
        payments_array.push(
          [
            {text: 'Name', alignment: 'center', style: 'label'},
            {text: 'Amount', alignment: 'center', style: 'label'},
            {text: 'Remarks', alignment: 'center', style: 'label'}
          ]
        )
        if (this.payments_data.length > 0) {
          this.payments_data.forEach(function (item, index, payment) {
            payments_array.push(
              [
                {text: item.name, alignment: 'left'},
                {text: item.amount, alignment: 'center'},
                {text: item.payment_for, alignment: 'center'},
              ]
            )
            if (Object.is(payment.length - 1, index)) {
              // execute last item logic
              payments_array.push(
                [
                  {text: '', alignment: 'left', border: [false, false, false, false]},
                  {
                    text: 'Net:',
                    alignment: 'right',
                    border: [false, false, false, false],
                  },
                  {
                    text: total_net,
                    alignment: 'left',
                    border: [false, false, false, false],
                  },
                ]
              )
              payments_array.push(
                [
                  {text: '', alignment: 'left', border: [false, false, false, false]},
                  {
                    text: 'Dep. Amount:',
                    alignment: 'right',
                    border: [false, false, false, false],
                  },
                  {
                    text: dep_amount,
                    alignment: 'left',
                    border: [false, false, false, false],
                  },
                ]
              )
              payments_array.push(
                [
                  {text: '', alignment: 'left', border: [false, false, false, false]},
                  {
                    text: 'Balance:',
                    alignment: 'right',
                    border: [false, false, false, false],
                  },
                  {
                    color: 'red',
                    text: total_diff,
                    alignment: 'left',
                    border: [false, false, false, false],
                  },
                ]
              )
            }
          })
        } else {
          payments_array.push(
            [
              '----',
              '----',
              '----',
            ]
          )
        }
        //call pdfmake
        var pdfMake = require('pdfmake/build/pdfmake.js')
        if (pdfMake.vfs == undefined) {
          var pdfFonts = require('pdfmake/build/vfs_fonts.js')
          pdfMake.vfs = pdfFonts.pdfMake.vfs;
        }
        var docDefinition = {
          pageSize: 'LETTER',
          content: [
            {
              columns: [
                {image: imgData, width: 54, height: 54, style: 'logo'},
                {
                  stack: [
                    'GOODLIFE COMMUNITY CARE FOUNDATION',
                    {text: 'ADDRESS', style: 'subheader'},
                    {
                      text: 'S.E.C REG. No. WAITING CONTACT NO.: waiting',
                      style: 'subheader'
                    },
                  ],
                  style: 'header'
                },
              ]
            },
            {text: 'REMITTANCE SLIP', style: 'title'},
            '================================================================================',
            {text: 'Printed as of: ' + moment().format('MMMM D, YYYY'), style: 'printed_label'},
            {text: 'Month of: ' + month, style: 'main_info'},
            {text: 'Collector: ' + collector, style: 'main_info'},
            {text: 'Dep. ID: ' + dep_id, style: 'main_info', color: 'red'},
            ' ',
            {
              style: 'tableExample',
              color: '#444',
              table: {
                widths: [160, 80, 120],
                body: payments_array,
              },
              layout: {
                fillColor: function (rowIndex) {
                  return (rowIndex === 0) ? '#CCCCCC' : null;
                },
              },
            },
            {
              stack: [
                {
                  columns: [
                    {text: 'Prepared By: ', style: 'aa_top_margin'},
                    {text: 'Received By:', style: 'cashier_top_margin'},
                    {text: ''},
                  ]
                },
                {
                  columns: [
                    {text: '', style: 'aa_for'},
                    {text: '', style: 'cashier_for'},
                    {text: ''},
                  ]
                },
                {
                  columns: [
                    {text: '_________________________________', style: 'aa_line'},
                    {text: '_________________________________', style: 'cashier_line'},
                    {text: ''},
                  ]
                },
                {
                  columns: [
                    {text: 'Collector', style: 'aa_placeholder'},
                    {text: 'Cashier', style: 'cashier_placeholder'},
                    {text: ''},
                  ]
                },
              ]
            },
            {
              stack: [
                {
                  columns: [
                    {text: 'Verified By: ', style: 'cs_top_margin'},
                    {text: 'Verified By: ', style: 'bm_top_margin'},
                    {text: 'Audited By: ', style: 'au_top_margin'},
                  ]
                },
                {
                  columns: [
                    {text: '', style: 'cs_for'},
                    {text: '', style: 'bm_for'},
                    {text: '', style: 'au_for'},
                  ]
                },
                {
                  columns: [
                    {text: '_________________________________', style: 'cs_line'},
                    {text: '_________________________________', style: 'bm_line'},
                    {text: '_________________________________', style: 'au_line'},
                  ]
                },
                {
                  columns: [
                    {text: 'Collection Supervisor', style: 'cs_placeholder'},
                    {text: 'Branch Manager', style: 'bm_placeholder'},
                    {text: 'Auditor', style: 'au_placeholder'},
                  ]
                },
              ]
            },

          ],
          footer: {
            columns: [
              {
                text: 'Generated with Goodlife Foundation System',
                alignment: 'center',
                style: 'tableExample'
              }
            ]
          },
          styles: {
            header: {
              fontSize: 21,
              bold: true,
              alignment: 'left',
              margin: [6, 6, 0, 20]//[left, top, right, bottom]
            },
            subheader: {
              fontSize: 12
            },
            title: {
              fontSize: 15,
              alignment: 'center',
            },
            logo: {
              alignment: 'center',
              margin: [0, 0, 0, 0]//[left, top, right, bottom]
            },
            printed_label: {
              alignment: 'right',
              fontSize: 9,
              margin: [0, 4, 0, 0]//[left, top, right, bottom]
            },
            main_info: {
              margin: [0, 2, 0, 2],
              fontSize: 10,
            },
            sub_info: {
              margin: [0, 2, 0, 2],
              fontSize: 8,
            },
            tableExample: {
              fontSize: 9,
            },

            aa_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            cashier_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            bm_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            cs_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            aa_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cashier_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cs_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            bm_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            aa_placeholder: {
              margin: [15, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            aa_for: {
              margin: [0, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cs_for: {
              margin: [2, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cashier_for: {
              margin: [3, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            bm_for: {
              margin: [3, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cashier_placeholder: {
              margin: [26, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cs_placeholder: {
              margin: [2, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            bm_placeholder: {
              margin: [5, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            au_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            au_placeholder: {
              margin: [2, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            au_for: {
              margin: [2, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            au_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
          },
        }
        pdfMake.createPdf(docDefinition).open();
      },
    }
  }
</script>
