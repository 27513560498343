<template>
  <div>
    <v-card
      flat
      dense
    >
      <v-form ref="form" lazy-validation>
        <v-flex xs12>
          <v-layout row wrap align-center>
            <v-flex xs12 md12
                    v-if="this.payment_option==='Induction Payment' || this.payment_option==='Tickets Payment'">
              <v-select
                outlined
                v-model="induction_details"
                item-text="details"
                item-value="id"
                class="mx-2"
                :items="induction_items"
                label="Induction Details"
                required
                @change="selected_induction"
              ></v-select>
              <v-text-field
                :min="0"
                type="number"
                v-model="amount"
                label="Amount"
                class="mx-2"
                required
                :rules="rules.default_max_45_character_and_no_empty_rule"
              ></v-text-field>
              <v-text-field
                v-model="year_of"
                label="Year of"
                class="mx-2"
                required
                readonly
                :rules="rules.default_max_45_character_and_no_empty_rule"
              ></v-text-field>
              <v-text-field
                v-model="location"
                label="Location"
                class="mx-2"
                required
                readonly
                :rules="rules.default_max_255_character_and_no_empty_rule"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 md12 v-else>
              <v-text-field

                v-model="amount"
                :min="0"
                type="number"
                label="Amount"
                class="mx-2"
                required
                :rules="rules.default_max_45_character_and_no_empty_rule"
                readonly
              ></v-text-field>
            </v-flex>
            <!-- alert -->
            <v-col cols="12" v-show="alert">
              <v-alert
                color="warning"
                text
                class="mb-0"

              >
                <div class="d-flex align-start">
                  <v-icon color="warning">
                    {{ icons.mdiAlertOutline }}
                  </v-icon>

                  <div class="ms-3">
                    <p class="text-base font-weight-medium mb-1">
                      {{alert_message}}
                    </p>
                  </div>
                </div>
              </v-alert>
            </v-col>

            <v-col cols="12">
              <v-btn
                color="primary"
                class="me-3 mt-4"
                @click="save_payments"
                v-if="!saving"
              >
                Save changes
              </v-btn>
              <v-progress-circular
                :size=50
                :width="5"
                color="primary"
                indeterminate
                v-else
              ></v-progress-circular>
            </v-col>
          </v-layout>
        </v-flex>
      </v-form>
    </v-card>
    <snack-bar-dialog
      :snackbar_flag="this.snackbar"
      :color="this.snackbar_color"
      :snackbar_text="this.snackbar_text"
    />
  </div>
</template>

<script>
  import {mapActions, mapGetters} from "vuex";
  import {mdiAlertOutline, mdiCloudUploadOutline} from "@mdi/js";
  import moment from 'moment'
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'

  export default {
    props: {
      collector_id: Number,
      payment_for: String,
      payment_option: String,

      selected_search_items: Object,

      deposit_slip_id: Number,
      balance: Number,
    },
    components: {
      snackBarDialog,
    },
    setup() {
      return {
        icons: {
          mdiAlertOutline,
          mdiCloudUploadOutline,
        },
      }
    },
    data() {
      return {
        saving: false,
        alert: false,
        alert_message: '',
        year_of: '',
        location: '',

        amount: 0,
        amount_due: [],

        induction_details: '',
        induction_items: [],
      }
    },
    watch: {
      selected_search_items: function () {
        this.selected_payment_for()
      },
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('authentication', ['branch_id']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text']),
      ...mapGetters('authentication', ['month_of']),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('members_payment', ['register_new_members_payment']),
      ...mapActions('induction_history_payments', ['create_induction_payment']),
      ...mapActions('induction_tickets_payments', ['create_induction_ticket_payment']),
      selected_payment_for() {
        this.amount = '1000'
        this.induction_items = []
        if (this.payment_option === 'Induction Payment') {
          this.amount = ''
          if (this.selected_search_items != null && this.selected_search_items != undefined) {
            this.induction_items = this.selected_search_items.unpaid_induction
          }
        }
        if (this.payment_option === 'Tickets Payment') {
          this.amount = ''
          if (this.selected_search_items != null && this.selected_search_items != undefined) {
            this.induction_items = this.selected_search_items.tickets
          }
        }
      },
      selected_induction() {
        var index = this.induction_items.map(function (x) {
          return x.id;
        }).indexOf(this.induction_details)
        if (index != -1) {
          this.year_of = this.induction_items[index].year_of + ''
          this.location = this.induction_items[index].location
        }
      },
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      },
      save_payments() {
        this.saving = true
        this.alert = false
        if (this.$refs.form.validate()) {
          var proceed = true;
          var total_amount_to = this.amount
          if (this.balance <= 0
            || (this.balance - total_amount_to) < -100) {
            proceed = false;
            this.alert = true
            this.alert_message = 'Please Load Deposit Slip First'
            this.saving = false
          }
          //if no error above then proceed
          if (proceed) {
            if (this.payment_option === 'Induction Payment') {
              this.create_induction_payment({
                member_id: this.selected_search_items.id,
                induction_id: this.induction_details,
                deposit_slip_id: this.deposit_slip_id,
                amount: this.amount,
                year_of: this.year_of,
                location: this.location.toUpperCase(),
                balance: this.balance - this.amount,
                collector_id: this.collector_id
              })
                .then(() => {
                  this.change_snackbar({
                    show: true,
                    color: 'success',
                    text: this.payment_option + ' SUCCESSFULLY !',
                  })
                  this.$emit('collector', this.collector_id)
                  this.saving = false
                })
                .catch(error => {
                  this.alert = true
                  this.alert_message = error
                  this.saving = false
                })
            } else if (this.payment_option === 'Tickets Payment') {
              this.create_induction_ticket_payment({
                induction_ticket_id: this.induction_details,
                deposit_slip_id: this.deposit_slip_id,
                amount: this.amount,
                year_of: this.year_of,
                location: this.location.toUpperCase(),
                balance: this.balance - this.amount,
                collector_id: this.collector_id
              })
                .then(() => {
                  this.change_snackbar({
                    show: true,
                    color: 'success',
                    text: this.payment_option + ' SUCCESSFULLY !',
                  })
                  this.$emit('collector', this.collector_id)
                  this.saving = false
                })
                .catch(error => {
                  this.alert = true
                  this.alert_message = error
                  this.saving = false
                })
            } else {
              this.register_new_members_payment({
                branch_id: this.branch_id,
                member_id: this.selected_search_items.id,
                deposit_slip_id: this.deposit_slip_id,
                amount: this.amount,
                balance: this.balance - this.amount,
                collector_id: this.collector_id
              })
                .then(() => {
                  this.change_snackbar({
                    show: true,
                    color: 'success',
                    text: this.payment_option + ' SUCCESSFULLY !',
                  })
                  this.$emit('collector', this.collector_id)
                  this.saving = false
                })
                .catch(error => {
                  this.alert = true
                  this.alert_message = error
                  this.saving = false
                })
            }
          }
        } else {
          this.alert = true
          this.alert_message = 'Please Fill up the field/s'
          this.saving = false
        }
      },
    }
  }
</script>
